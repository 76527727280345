import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "@envs/environment";
import {ProductEnvironment} from "@enums/productEnvironment.enum";
import {ProductType} from "@enums/productType.enum";
import {BankRefill} from "@models/bankRefill.model";
import {MyProduct} from "@models/myProduct.model";
import {Product} from "@models/product.model";
import {SearchResult} from "@models/searchResult.model";
import {map} from "rxjs/operators";
import {ProductWithDate} from "@models/productWithDate.model";
import {TypeCovers} from "../enums/typeCovers.enum";
import {ProductChild} from "../models/productChild.model";
import {OrderDetail} from "../models/orderDetail.model";

@Injectable({providedIn: 'root'})
export class ProductService {

  public products: BehaviorSubject<Product[]>
  public myProducts: BehaviorSubject<MyProduct[]>
  public productDetails: BehaviorSubject<Product[]>
  public myProductDetails: BehaviorSubject<MyProduct[]>
  public subscribedProducts: BehaviorSubject<string[]>
  public basketProducts: Product[] = [];
  public userBasket: OrderDetail[] = [];

  private currentProductCountSubject: BehaviorSubject<number>;

  constructor(private http: HttpClient) {
    this.products = new BehaviorSubject<Product[]>([])
    this.myProducts = new BehaviorSubject<MyProduct[]>([])
    this.productDetails = new BehaviorSubject<Product[]>([])
    this.myProductDetails = new BehaviorSubject<MyProduct[]>([])
    this.subscribedProducts =
      new BehaviorSubject<string[]>([])
    this.currentProductCountSubject =
      new BehaviorSubject<number>(JSON.parse(localStorage.getItem('productCount') || '0'))
  }

  getProduct(productId: number): Promise<Product> {
    return this.http.get <Product>(`${environment.apiUrl}/Products/GetProduct/${productId}`)
      .toPromise()
  }

  getProductMinimal(productId: number): Promise<Product> {
    return this.http.get <Product>(`${environment.apiUrl}/Products/GetProductMinimal/${productId}`)
      .toPromise()
  }

  getMyProductMinimalUnauthorized(email: string, productId: number): Promise<MyProduct> {
    return this.http.get <MyProduct>(`${environment.apiUrl}/Products/GetMyProductMinimalUnauthorized/${email}/${productId}`)
      .toPromise()
  }

  getProductMinimalByName(productName: string): Promise<Product> {
    return this.http.get <Product>(`${environment.apiUrl}/Products/GetProductMinimalByName/${productName}`)
      .toPromise()
  }

  getMyProduct(productId: number, productEnvironment?: ProductEnvironment, isSubscribed?: boolean): Promise<MyProduct> {
    return this.http.get <MyProduct>(`${environment.apiUrl}/Products/GetMyProduct/${productId}?productEnvironment=${productEnvironment}&isSubscribed=${isSubscribed}`)
      .toPromise()
  }

  getMyProductMinimal(productId: number): Promise<MyProduct> {
    return this.http.get <MyProduct>(`${environment.apiUrl}/Products/GetMyProductMinimal/${productId}`)
      .toPromise()
  }

  getMyProductMinimalByName(name: string): Promise<MyProduct> {
    return this.http.get <MyProduct>(`${environment.apiUrl}/Products/GetMyProductMinimalByName/${name}`)
      .toPromise()
  }

  getMyProductWithDate(productId: number, isAssistenza: boolean): Promise<ProductWithDate> {
    return this.http.get <ProductWithDate>(`${environment.apiUrl}/Products/GetMyProductWithDate/${productId}/${isAssistenza}`)
      .toPromise()
  }

  getProductChild(parentId: number, childId: number): Promise<ProductChild> {
    return this.http.get <ProductChild>(`${environment.apiUrl}/Products/GetProductChild/${parentId}/${childId}`)
      .toPromise()
  }

  searchProducts(search: string, page: number, count: number, productId: number, productType?: ProductType, productEnvironment?: ProductEnvironment, typeCovers?: TypeCovers, isOptional?: boolean): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/Products/SearchProducts`, {
      search: search,
      page: page,
      count: count,
      productId: productId,
      productType: productType,
      productEnvironment: productEnvironment,
      typeCovers: typeCovers,
      isOptional: isOptional
    }).toPromise()
  }

  searchMyProducts(search: string, page: number, count: number, productId: number): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/Products/SearchMyProducts`, {
      search: search,
      page: page,
      count: count,
      productId: productId
    }).toPromise()
  }

  searchMyProductsForIndicatorRequests(): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/Products/searchMyProductsForIndicatorRequests`, {}).toPromise()
  }

  searchMyProductsMinimal(search: string, page: number, count: number, productId: number): Promise<any> {
    return this.http.post <any>(`${environment.apiUrl}/Products/SearchMyProductsMinimal`, {
      search: search,
      page: page,
      count: count,
      productId: productId
    }).pipe(map((_myProducts: SearchResult<MyProduct>) => {
      if (_myProducts.totalCount > 0) {
        this.myProducts.next(_myProducts.result)
      }
    })).toPromise()
  }

  getProductRefill(productId: number): Promise<BankRefill> {
    return this.http.get <BankRefill>(`${environment.apiUrl}/Products/GetProductRefill/${productId}`)
      .toPromise()
  }

  addProductToBasket(product: Product) {
    this.basketProducts = JSON.parse(localStorage.getItem('basketProducts') || '[]')
    this.basketProducts.push(product);
    this.updateProductCount(this.basketProducts.length)
    localStorage.setItem('basketProducts', JSON.stringify(this.basketProducts))
  }

  getProductBasket() {
    this.basketProducts = JSON.parse(localStorage.getItem('basketProducts') || '[]')
  }

  getProductCount(): Observable<number> {
    return this.currentProductCountSubject.asObservable();
  }

  updateProductCount(count: number) {
    localStorage.setItem('productCount', JSON.stringify(count))
    this.currentProductCountSubject.next(count)
  }

  getBasketTotal() {
    return this.basketProducts.reduce(function (prev, cur) {
      return prev + cur.price;
    }, 0)
  }

  polishProductName(productName: string) {
    function capitalizeTheFirstLetterOfEachWord(words) {
      let separateWord = words.toLowerCase().split('-');
      for (let i = 0; i < separateWord.length; i++) {
        separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
      }
      return separateWord.join(' ');
    }

    return capitalizeTheFirstLetterOfEachWord(productName)
  }

  removeProductFromBasket(product: Product) {
    function arrayRemove(array: Product[], value: number) {
      return array.filter(function (product: Product) {
        return product.productId != value;
      })
    }

    this.basketProducts = JSON.parse(localStorage.getItem('basketProducts') || '[]')
    this.basketProducts = arrayRemove(this.basketProducts, product.productId)
    localStorage.setItem('basketProducts', JSON.stringify(this.basketProducts))
    this.updateProductCount(this.basketProducts.length)
  }

  emptyCart() {
    localStorage.removeItem('basketProducts')
    localStorage.removeItem('productCount')
  }
}
