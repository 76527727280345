import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from "@services/products.service";

@Component({
  selector: 'app-video-frame',
  templateUrl: './video-frame.component.html',
  styleUrls: ['./video-frame.component.scss']
})
export class VideoFrameComponent implements OnInit {
  videoUrl: SafeResourceUrl = '';
  email: string;
  lessonName: string;
  productId: number;

  constructor(private sanitizer: DomSanitizer, private route: ActivatedRoute, private productService: ProductService) {
  }

  ngOnInit(): void {
    console.log('Dati del componente video-frame:');
    this.route.queryParams.subscribe(async params => {
      this.email = params['email'];
      this.lessonName = params['product_name'];
      this.productId = params['product_id'];
      console.log('email:', this.email);
      console.log('lessonName:', this.lessonName);
      console.log('videoId:', this.productId);

      if (this.productId) {
        const result = await this.productService.getMyProductMinimalUnauthorized(this.email, this.productId);
        const clipId = result.vimeoLink.split('/').pop();
        const url = `https://player.vimeo.com/video/${clipId}`; // forbidden
        // const url = `${result.vimeoLink}`; // // sameOrigin
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        console.log('Sanitized videoUrl:', this.videoUrl as string);
      } else {
        console.error('Non è stato trovato nessun product_id');
      }
    });
  }
}
