import {Component, OnInit} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {IAuthenticateResponse} from './helpers/models/authenticate.model';
import {AuthService} from './helpers/services/auth.service';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe-decorator';
import {ProductService} from '@/helpers/services/products.service';
import {AmmiratoreLiveService} from '@/helpers/services/ammiratore-live.service';
import {ToastrService} from 'ngx-toastr';
import {SignalService} from '@/helpers/services/signals.services';
import {DiscordPattern} from '@/helpers/patterns/validators.patterns';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Top-Trading Academy';

  currentUser: IAuthenticateResponse;
  invite: string = '';
  isPlaying: boolean = false;
  hideElements: boolean = false;

  @AutoUnsubscribe()
  userSubscriptions: Subscription;

  @AutoUnsubscribe()
  ammiratoreSubscription: Subscription;

  constructor(
    private toastr: ToastrService,
    private auth: AuthService,
    private route: ActivatedRoute,
    public router: Router,
    private authService: AuthService,
    private ammiratoreLiveService: AmmiratoreLiveService,
    private signalService: SignalService,
    private productService: ProductService,
    private metaTagService: Meta
  ) {
    this.route.queryParams.subscribe(params => {
      this.invite = params['invite'];
      if (this.invite != '' && this.invite != undefined) {
        this.saveInvite();
      }
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        console.log('NavigationEnd event:', event);
        this.hideElements = event.url.startsWith('/video');
        console.log('hideElements set to:', this.hideElements);
      });
  }

  async ngOnInit() {
    console.log('AppComponent initialized');
    document.documentElement.style.setProperty('--plyr-color-main', '#D97706');
    this.userSubscriptions = this.authService.currentUserSubject.subscribe(user => {
      this.currentUser = user;
      if (this.currentUser === null) {
        this.signalService.signals.next([]);
        this.signalService.ammiratoreCustomHeader = null;
        this.productService.myProducts.next([]);
        this.productService.myProductDetails.next([]);
        this.productService.subscribedProducts.next([]);
      }
    });

    this.userSubscriptions.add(this.auth.isUserPlayingSubject.subscribe(playing => {
      this.isPlaying = playing;
    }));

    this.ammiratoreSubscription = this.ammiratoreLiveService.ammiratoreNotificationSubject.subscribe(async last => {
      if (last != null) {
        await this.playNotification(last.sender);
      }
    });

    this.metaTagService.addTags([
      {name: 'keywords', content: 'Trading Ciclico, Trading Volumetrico, Accademia di trading'},
      {name: 'author', content: 'TTGames Srl'},
      {charset: 'UTF-8'}
    ]);
    if (this.currentUser && this.currentUser.jwtToken) {
      await this.authService.refreshToken().catch(() => {
      });
    }
  }

  saveInvite() {
    localStorage.setItem('tta-invite', JSON.stringify(this.invite));
  }

  showDiscordRedirectModal() {
    if (this.currentUser && this.currentUser?.jwtToken && !DiscordPattern.test(this.currentUser.username)) {
      this.authService.shouldShowPendingDiscordRedirection.next(true);
    }
  }

  async playNotification(sender: string) {
    if (!document.hasFocus()) {
      document.title = 'Nuova Operazione';
      setTimeout(() => {
        document.title = 'Ammiratore | Top-Trading Academy';
      }, 5000);
    }
    this.toastr.success(`Ricevuta un nuova operazione da Ammiratore`, 'Nuova operazione!', {
      timeOut: 5000,
      progressBar: true,
      progressAnimation: 'increasing'
    });

    const audio = new Audio('assets/sounds/ammiratore_notification.wav');
    await audio.play();
  }
}
