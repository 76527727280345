import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContactsComponent} from './components/contacts/contacts.component';
import {HelpComponent} from './components/help/help.component';
import {TermsConditionsComponent} from './components/terms-conditions/terms-conditions.component';
import {AuthGuard} from './helpers/guards/auth.guard';
import {HomeComponent} from './pages/home/home.component';
import {AdminGuard} from "@/helpers/guards/admin.guard";
import {DisclaimerComponent} from "@/components/disclaimer/disclaimer.component";
import {VideoFrameComponent} from './pages/video-frame/video-frame.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module')
      .then(m => m.DashboardModule)
  },
  {
    path: 'shop',
    loadChildren: () => import('./pages/products/products.module')
      .then(m => m.ProductsModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module')
      .then(m => m.AccountModule)
  },
  {
    path: 'king-of-trading',
    canActivate: [AdminGuard],
    loadChildren: () => import('./pages/king-of-trading/king-of-trading.module')
      .then(m => m.KingOfTradingModule)
  },
  /*{
    path: 'radio-radio',
    loadChildren: () => import('./pages/radio-radio/radio-radio.module')
      .then(m => m.RadioRadioModule)
  },*/
  {
    path: 'ammiratore',
    loadChildren: () => import('./pages/ammiratore/ammiratore.module')
      .then(m => m.AmmiratoreModule)
  },
  /*{
    path: 'obiettivo-250',
    loadChildren: () => import('./pages/obiettivo-duemezzo/obiettivo-duemezzo.module')
      .then(m => m.ObiettivoDuemezzoModule)
  },*/
  {path: 'help', component: HelpComponent},
  {path: 'termini-condizioni', component: TermsConditionsComponent},
  {path: 'disclaimer', component: DisclaimerComponent},
  {path: 'contacts', component: ContactsComponent},
  {path: 'video', component: VideoFrameComponent, data: {standalone: true}},
  {path: '**', redirectTo: '/', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0], // [x, y]
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
